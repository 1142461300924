.sidebar-config {
  background-color: #ff4347;
}

.sidebar-list {
  padding-top: 3rem !important;
}

.sidebar-list-item {
  color: white;
}
