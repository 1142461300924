.MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.MuiInputBase-formControl {
  border-radius: 0px !important;
  background-color: #f2f2f2;
  padding-left: 10px !important;
  padding-right: 5px !important;
}
.MuiFormHelperText-root {
  padding-left: 100px !important;
}
.MuiSelect-root {
  padding-left: 100px !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate3d(-80px, 20px, 10px) !important;
}

/* Remove default color autocomplete from chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #f2f2f2 inset !important;
}
