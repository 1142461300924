.App {
  text-align: center;
}

.login-container {
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.enwesa-logo {
  width: 50px;
}

.soincon-logo {
  width: 100px;
}
.MuiFormControl-root,
.MuiTextField-root,
.MuiFormControl-fullWidth {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.login-paper {
  height: 395px;
  width: 370px;
  max-width: calc(100% - 22px);
  justify-self: center;
  display: flex;
  box-shadow: 1px 1px 50px 0px rgba(0, 0, 0, 0.4);
}

.login-paper-loading {
  width: 370px;
  height: 416px;
  max-width: calc(100% - 22px);
  justify-self: center;
  display: flex;
}
.submit-button {
  color: white !important;
  background-color: #ff4347 !important;
  box-shadow: none !important;
  width: 38%;
  position: fixed !important;
  bottom: 25px;
}
img {
  height: 100% !important;
  vertical-align: initial !important;
}
.row-logo {
  height: 45px;
  padding: 8px;
  position: absolute;
  left: 5%;
  top: 5%;
}
