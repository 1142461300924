.login-button {
  position: absolute;
  left: auto;
  right: 10px;
  top: 10px;
  z-index: 9999999;
}

.back-button {
  position: absolute;
  left: 10px;
  right: 10px;
  top: 10px;
  z-index: 9999999;
}

.wrapper-animation {
  animation: slide 0.5s infinite;
  animation-delay: 2s;
}

.segment {
  padding: 0 !important;
}

.pushable {
  border: none !important;
  box-shadow: none !important;
  margin: 0% !important;
}
.img-div {
  padding-left: 120px !important;
  padding-right: 120px !important;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.img-dimension {
  padding-top: 2rem;
  pointer-events: none !important;
  width: 220px !important;
  height: 100px !important;
}
